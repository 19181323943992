import React, { useState, useRef } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { Fab } from "@mui/material";
import "./CertificationsDesktop.css";
import Certification from "../v2/Certification";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

//comentar depois
import certification_example from "../../img/global-organic-certification.png"

const CertificationsDesktop = (/* { certifications } */) => {
  const carouselRef = useRef();
  const [selectedCertification, setSelectedCertification] = useState(null);

  const handleImageClick = (certification) => {
    setSelectedCertification(certification);
  };

  const handleClose = () => {
    setSelectedCertification(null);
  };

  const certifications = [
    {
      img: certification_example,
      title: "um titulo",
      description: "This is an example",
    },
    {
      img: certification_example,
      title: "um titulo",
      description: "This is an example",

    },
    {
      img: certification_example,
      title: "um titulo",
      description: "This is an example",

    },
    {
      img: certification_example,
      title: "um titulo",
      description: "This is an example",

    },
  ];

  return (
    <div className="certifications-desktop-container">
      <div className="certifications-desktop-title">CERTIFICATIONS</div>
      <div className="certifications-desktop-carousel-container">
        {selectedCertification ? (
          <div className="certification-detail">
            <img
              src="./images/close-icon.svg"
              className="certification-close-button"
              alt="certification-close-button"
              onClick={handleClose}
            />
            <Certification
              img={selectedCertification.img}
              title={selectedCertification.title}
              description={selectedCertification.description}
            />
          </div>
        ) : (
          <div className="certifications-desktop-carousel">
            <Fab
              className="material-button-left"
              size="small"
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
              onClick={() => carouselRef.current?.goBack()}
            >
              <KeyboardArrowLeft style={{ fontSize: 20 }} />
            </Fab>
            <ResponsiveContainer
              carouselRef={carouselRef}
              render={(parentWidth, carouselRef) => (
                <StackedCarousel
                  ref={carouselRef}
                  data={certifications}
                  carouselWidth={parentWidth}
                  slideWidth={60
                  }
                  slideComponent={(props) => (
                    <Slide {...props} onClick={handleImageClick} />
                  )}
                  maxVisibleSlide={3}
                />
              )}
            />
            <Fab
              className="material-button-right"
              size="small"
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
              onClick={() => carouselRef.current?.goNext()}
            >
              <KeyboardArrowRight style={{ fontSize: 20 }} />
            </Fab>
          </div>
        )}
      </div>
    </div>
  );
};

// Slide component to be used inside StackedCarousel
const Slide = React.memo(function (props) {
  const { data, dataIndex, onClick } = props;
  const certification = data[dataIndex];
  return (
    <div
      className="certification-carousel-item"
      onClick={() => onClick(certification)}
    >
      <img
        src={certification.img}
        alt="certification"
        className="certification-img"
      />
    </div>
  );
});

export default CertificationsDesktop;
