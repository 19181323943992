import React from 'react';
import './ScoreInfo.css';
import ScoreHorizontal from './ScoreHorizontal';
import { useState,useEffect } from "react";

/* Recebe um icone, título, descrição e score e apresenta a métrica alterando a cor do score consoante escolhida */

const ScoreInfo = ({ icon, title, description, score, currentPage }) => {
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [colorClass, setColorClass] = useState("red");
  const handleLetterChange = (letter) => {
    switch (letter) {
      case "A":
        setColorClass("color-1");
        break;
      case "B":
        setColorClass("color-2");
        break;
      case "C":
        setColorClass("color-3");
        break;
      case "D":
        setColorClass("color-4");
        break;
      case "E":
        setColorClass("color-5");
        break;
      case "F":
        setColorClass("color-6");
        break;
      default:
        setColorClass("white");
    }
  };

  const headerStyle = {
    width: currentPage === 1 ? "100%" : "100%",
  };

  return isMobile ? (
    <div className="score-container">
      <div className="score-header" style={headerStyle}>
        <img src={icon} alt="" className="score-icon-af" />
        <span className="score-title-v2">{title}</span>
        <span className={`score-value ${colorClass}`}>{score}%</span>
      </div>
      <div className="score-info">
        <div className="separator-container">
          <div className="separator"></div>
        </div>
        <div className="score-grades-description">
          <div className="score-grades">
            <ScoreHorizontal
              score={score}
              onLetterChange={handleLetterChange}
            ></ScoreHorizontal>
          </div>
          {description && (
            <div className="score-description">{description}</div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="score-container-desktop">
      <div className="score-info-desktop">
        <div className="score-header-desktop" style={headerStyle}>
          <img src={icon} alt="" className="score-icon-af" />
          <span className="score-title-v2">{title}</span>
          <span className={`score-value ${colorClass}`}>{score}%</span>
        </div>
        <div className="score-grades-description-desktop">
          <div className="score-grades-desktop">
            <ScoreHorizontal
              score={score}
              onLetterChange={handleLetterChange}
            ></ScoreHorizontal>
          </div>
          {description && (
            <div className="score-description">{description}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScoreInfo;