import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSwipeable } from "react-swipeable";
import "./SimilarProducts.css";
import ProductItemSimilar from "./ProductItemSimilar";
import arrow_up from "../../img/up_arrow.svg";
import arrow_down from "../../img/down_arrow.svg";

const SimilarProducts = forwardRef(({ productList, handleMainpage }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alwaysOpen, setAlwaysOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  useEffect(() => {
    if (productList.length <= 3) {
      setAlwaysOpen(true);
      setIsOpen(true);
    }
  }, [productList]);

  const onSwipedUp = () => {
    if (!alwaysOpen) setIsOpen(true);
  };

  const onSwipedDown = () => {
    if (!alwaysOpen) setIsOpen(false);
  };

  const onClickOverlay = () => {
    if (!alwaysOpen) setIsOpen(false);
  };

  const onClickOverlayDesktop = () => {
    if (!alwaysOpen) setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    onSwipedUp,
    onSwipedDown,
  }));

  // Swipe handlers only active when the menu is closed
  const handlers = useSwipeable({
    onSwipedDown,
    onSwipedUp,
    trackMouse: true,
    delta: 50,
    disableScrollLock: !isOpen, // Disable scroll lock when the menu is open
  });

  const panelClass = alwaysOpen ? "always-open" : isOpen ? "open" : "closed";
  const panelClassDesktop = isHovered ? "open-desktop" : "closed-desktop";

  return isMobile ? (
    <>
      <div className="swipe-similar">
        <div
          className={`swipe-panel-similar-products  ${panelClass}`}
          {...handlers}
        >
          {!alwaysOpen ? (
            <div className="arrow-container-similar-products" >
              {isOpen ? (
                <img onClick={onSwipedDown} src={arrow_down} alt="Arrow Down" />
              ) : (
                <img onClick={onSwipedUp} src={arrow_up} alt="Arrow Up" />
              )}
            </div>
          ) : (
            ""
          )}

          <div className="container-similar-products">
            <p className="container-title-similar-products">Similar Products</p>
            <div className="container-images-similar-products-v2">
              {productList.map((product) => (
                <div className="product-item-similar-products" key={product.id}>
                  <ProductItemSimilar
                    productObject={product}
                    handleMainpage={handleMainpage}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {!alwaysOpen && isOpen && (
        <div className="overlay-products-similar" onClick={onClickOverlay}>
          <div className="teste"></div>
        </div>
      )}
    </>
  ) : (
    <>
      <div className="swipe-similar">
        <div
          className={`swipe-panel-similar-products  ${panelClass}`}
          {...handlers}
        >
          {!alwaysOpen ? (
            <div className="arrow-container-similar-products" onClick={isOpen ? onSwipedDown : onSwipedUp}>
              {isOpen ? (
                <img onClick={onSwipedDown} src={arrow_down} alt="Arrow Down" />
              ) : (
                <img onClick={onSwipedUp} src={arrow_up} alt="Arrow Up" />
              )}
            </div>
          ) : (
            ""
          )}

          <div className="container-similar-products">
            <p className="container-title-similar-products">Similar Products</p>
            <div className="container-images-similar-products-v2">
              {productList.map((product) => (
                <div className="product-item-similar-products" key={product.id}>
                  <ProductItemSimilar
                    productObject={product}
                    handleMainpage={handleMainpage}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {!alwaysOpen && isOpen && (
        <div className="overlay-products-similar" onClick={onClickOverlay}>
          <div className="teste"></div>
        </div>
      )}
    </>
  );
});

export default SimilarProducts;
