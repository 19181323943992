import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./ProductHeaderV2.css";
import ScoreV2 from "../../components/v2/ScoreV2";

const ProductHeaderV2 = ({ product_info, score, handleNavigation }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const imgRef = useRef(null);
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  useEffect(() => {
    const img = new Image();
    img.src = product_info.img;
    img.onload = () => {
      setImageLoaded(true);
      setLoading(false);
    };
  }, [product_info.img]);

  const handleImageClick = () => {
    const img = imgRef.current;
    const rect = img.getBoundingClientRect();
    const initialPosition = {
      top: rect.top,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    };

    localStorage.setItem("initialPosition", JSON.stringify(initialPosition));
    handleNavigation();
  };

  return isMobile ? (
    <div className="v2-product-header-container">
      {loading && <div className="v2-product-header-img-placeholder"></div>}
      <CSSTransition
        in={imageLoaded}
        timeout={400}
        classNames="v2-image-fade-header"
        unmountOnExit
        appear
      >
        <img
          ref={imgRef}
          className="v2-product-header-img"
          alt="product-header-img"
          src={product_info.img}
          onClick={handleImageClick}
        />
      </CSSTransition>
      <div className="v2-product-header-text-score-container">
        <div className="v2-product-header-text-container">
          <div className="v2-product-header-text-name-v2">
            {product_info.name}
          </div>
          <div className="v2-product-header-text-brand-v2">
            {product_info.brand}
          </div>
        </div>
        <ScoreV2 score={score} />
      </div>
    </div>
  ) : (
    <div className="v2-product-header-container">
      {loading && <div className="v2-product-header-img-placeholder"></div>}
      <CSSTransition
        in={imageLoaded}
        timeout={400}
        classNames="v2-image-fade-header"
        unmountOnExit
        appear
      >
        <img
          ref={imgRef}
          className="v2-product-header-img"
          alt="product-header-img"
          src={product_info.img}
          onClick={handleImageClick}
        />
      </CSSTransition>
      <div className="v2-product-header-text-score-container">
        <div className="v2-product-header-text-container">
          <div className="v2-product-header-text-name-v2">
            {product_info.name}
          </div>
          <div className="v2-product-header-text-brand-v2">
            {product_info.brand}
          </div>
        </div>
        <ScoreV2 score={score} />
      </div>
    </div>
  );
};

export default ProductHeaderV2;
