import React, { useEffect, useRef, memo,useState } from 'react';
import './CompositionGraph.css';
import Chart from 'chart.js/auto';
//imports dos jsons para mapear tradução
import compositions from '../../pages/v2/jsons/compositions.json';

const CompositionGraph = ({ compositionList, recycled }) => {

  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Ensure compositionList is sorted by value descending
  compositionList.sort((a, b) => b.value - a.value);

  const chartRef = useRef(null);

  const getCompositionDescription = (code) => {
    const composition = compositions.find((composition) => composition.code === code);
    return composition ? composition.description.en : "Unknown Composition";
  };

  useEffect(() => {
    const handleResize = () => {
      const canvas = chartRef.current;
      const screenWidth = window.innerWidth;

      if (screenWidth <= 350) {
        canvas.width = 280;
        canvas.height = 100;
      } else if (screenWidth <= 600) {
        canvas.height = 130;
      } else if (screenWidth <= 1024) {
        canvas.height = 160;
      } else {
        canvas.height = 200;
        canvas.width = 400;
      }
    };

    // Set initial height based on screen width
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getFontSize = (baseSize) => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 350) {
      return `${baseSize * 0.7}px`; // Smaller screens
    } else if (screenWidth <= 600) {
      return `${baseSize * 0.95}px`; // Small screens
    } else if (screenWidth <= 1024) {
      return `${baseSize * 1.25}px`; // Medium screens
    } else {
      return `${baseSize * 1.5}px`; // Large screens
    }
  };

  const getMiddleValueSpace = (baseSize) => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 350) {
      return 24; // Smaller screens
    } else if (screenWidth <= 600) {
      return 28; // Small screens
    } else if (screenWidth <= 1024) {
      return 32; // Medium screens
    } else {
      return 36; // Large screens
    }
  };

  useEffect(() => {
    if (!compositionList || compositionList.length === 0) {
      const emptyDonutData = {
        datasets: [
          {
            data: [1],
            borderWidth: 0,
            backgroundColor: '#D1D1D1',
            weight: 0.05,
          },
          {
            data: [1],
            backgroundColor: 'white',
            borderWidth: 0,
          },
          {
            data: [1],
            borderWidth: 0,
            backgroundColor: '#D1D1D1',
            weight: 0.05,
          },
        ],
      };

      const emptyDonutConfig = {
        type: 'doughnut',
        data: emptyDonutData,
        options: {
          layout: {
            padding: 10,
          },
          cutout: '60%',
          plugins: {
            tooltip: {
              enabled: false, // Disable tooltips
            },
            legend: {
              display: false,
            },
          },
          hover: {
            mode: null, // Disable hover actions
          },
          animation: {
            duration: 0, // Disable animation for empty list
          },
        },
      };

      const emptyDonutChart = new Chart(chartRef.current, emptyDonutConfig);

      return () => {
        emptyDonutChart.destroy();
      };
    }

    // Calculate total value and minimum value threshold
    const totalValue = compositionList.reduce((sum, item) => sum + item.value, 0);
    const minThreshold = totalValue * 0.1;

    // Adjust values to ensure no slice is below the minimum threshold
    const adjustedData = compositionList.map(item => ({
      ...item,
      adjustedValue: Math.max(item.value, minThreshold)
    }));

    // Normalize adjusted values to maintain the total value
    const adjustedTotal = adjustedData.reduce((sum, item) => sum + item.adjustedValue, 0);
    const normalizedData = adjustedData.map(item => ({
      ...item,
      normalizedValue: (item.adjustedValue / adjustedTotal) * totalValue
    }));

    const colors = ['#0099CCB2', '#0097AE', '#004C71', '#0B3C6C', '#2A9D8F', '#E9C46A', '#F4A261', '#2A9D8F', '#E76F51'];
    const colorMap = {};

    normalizedData.forEach((item, index) => {
      // Assign color based on index to ensure unique colors
      const colorIndex = index % colors.length;
      colorMap[index] = colors[colorIndex];
    });

    const data = {
      labels: normalizedData.map((item) =>
        getCompositionDescription(item.code)
      ),
      datasets: [
        {
          data: normalizedData.map((item) => item.normalizedValue),
          backgroundColor: normalizedData.map((item, index) => colorMap[index]),
          borderWidth: 1, // border gap between slices
          borderColor: "white",
          hoverOffset: 0,
        },
      ],
    };

    // Labels and percentage display for each slice
    const doughnutLabelsLine = {
      id: 'doughnutLabelsLine',
      afterDraw(chart) {
        const { ctx } = chart;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
          const meta = chart.getDatasetMeta(datasetIndex);
          if (meta) {
            meta.data.forEach((element, index) => {
              const centerX =
                chart.chartArea.left +
                (chart.chartArea.right - chart.chartArea.left) / 2;
              const centerY =
                chart.chartArea.top +
                (chart.chartArea.bottom - chart.chartArea.top) / 2;
              const radius = Math.min(
                chart.chartArea.right - chart.chartArea.left,
                chart.chartArea.bottom - chart.chartArea.top
              ) / 2;

              const startAngle = element.startAngle;
              const endAngle = element.endAngle;
              const middleAngle = (startAngle + endAngle) / 2;

              const xStart = centerX + radius * Math.cos(middleAngle);
              const yStart = centerY + radius * Math.sin(middleAngle);
              const xLine = centerX + (radius + 12) * Math.cos(middleAngle);
              const yLine = centerY + (radius + 12) * Math.sin(middleAngle);
              const extraLine = xLine >= centerX ? 6 : -6;

              ctx.lineWidth = 0.5;
              ctx.beginPath();
              ctx.moveTo(xStart, yStart);
              ctx.lineTo(xLine, yLine);
              ctx.lineTo(xLine + extraLine, yLine);
              ctx.strokeStyle = 'black';
              ctx.stroke();

              const squareSize = 8;
              const squareX =
                xLine + extraLine - 1 * (xLine >= centerX ? 1 : 6);
              const squareY = yLine - squareSize / 2;
              const sliceColor = dataset.backgroundColor[index];
              ctx.fillStyle = sliceColor;
              ctx.fillRect(squareX, squareY, squareSize, squareSize);

              const label = chart.data.labels[index];
              ctx.font = `bold ${getFontSize(8.5)} Helvetica`;
              ctx.textAlign = xLine >= centerX ? 'left' : 'right';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = dataset.backgroundColor[index];
              ctx.fillText(
                label,
                xLine + extraLine + (xLine >= centerX ? 9 : -9),
                yLine + 1
              );

              const radiusValue =
                (Math.min(
                  chart.chartArea.right - chart.chartArea.left,
                  chart.chartArea.bottom - chart.chartArea.top
                ) /
                  2) *
                0.6;

              const xStartValue =
                centerX +
                radiusValue *
                  Math.cos(middleAngle);
              const yStartValue = centerY + radiusValue * Math.sin(middleAngle);

              const value =
                compositionList[index].value; // Use original value for percentage
              const percentage = value.toFixed(1) + '%';
              ctx.font = `bold ${getFontSize(7)} Helvetica`;
              ctx.fillStyle = 'white';
              ctx.fillText(
                percentage,
                xStartValue + (xLine >= centerX ? -2.5 : 4.8),
                yStartValue + (yLine >= centerY ? 2 : -5.5)
              );
            });
          }
        });

        // Draw the recycled value in the center
        ctx.save();
        ctx.font = `bold ${getFontSize(14)} Helvetica`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#1C698B';
        ctx.fillText(`${recycled}%`, chart.chartArea.width / 2, chart.chartArea.height / 2 + 18);
        ctx.restore();

        ctx.save();
        ctx.font = `bold ${getFontSize(7)} Helvetica`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#1C698B';
        ctx.fillText(
          `Recycled`,
          chart.chartArea.width / 2,
          chart.chartArea.height / 2 + getMiddleValueSpace(28)
        );
        ctx.restore();
      },
    };

    const config = {
      type: "doughnut",
      data: data,
      options: {
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 20,
          },
        },
        plugins: {
          tooltip: {
            enabled: false, // Disable tooltips
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          mode: "index", // Prevent hover interaction
        },
        cutout: "47%", // Adjust the doughnut size as needed
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
      },
      plugins: [doughnutLabelsLine],
    };

    const myChart = new Chart(chartRef.current, config);

    return () => {
      myChart.destroy();
    };
  }, [compositionList, recycled]);

  return (
    <div className='composition-container'>
     {!isMobile && <div className="composition-title-desktop"> Composition</div> }
      <div className='composition-line'>
        <div className='container-of-donut-container'>
          <canvas ref={chartRef} width='320' height='130'></canvas>
        </div>
      </div>
    </div>
  );
};

export default memo(CompositionGraph);
