import  { useState } from "react";
import React from 'react';
import ItemIndicator from './ItemIndicatorInfo';
import SimpleGraph from "./SimpleGraph";
import "./IndicatorInfo.css";

const IndicatorInfo = ({ data }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleItemClick = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  

  return isMobile ? (
    <div
      className={`container-detailedIndex ${
        expandedIndex !== null ? "has-expanded" : ""
      }`}
    >
      {data.map((item, index) => (
        <div className="detailexIndex-item-v2" key={index}>
          <ItemIndicator
            item={item}
            index={index}
            expandedIndex={expandedIndex}
            handleItemClick={handleItemClick}
            atlist={item.production_activities}
            valueObject={item}
          />

          {/* Renderiza a div condicional somente se expandedIndex for null */}
          {expandedIndex === null && (
            <div className="description-preview">
              <div className="description-preview-space"></div>
              <div className="decription-preview-content-v2">
                <div className="decription-preview-content-v2-p">
                  {item.description.main}
                </div>
                <SimpleGraph
                  atlist={item.production_activities}
                  valueObject={item}
                ></SimpleGraph>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  ) : (<div
    className={`container-detailedIndex-desktop ${
      expandedIndex !== null ? "has-expanded" : ""
    }`}
  >
    {data.map((item, index) => (
      <div className="detailexIndex-item-v2-desktop" key={index}>
        <ItemIndicator
          item={item}
          index={index}
          expandedIndex={expandedIndex}
          handleItemClick={handleItemClick}
          atlist={item.production_activities}
          valueObject={item}
        />

        {/* Renderiza a div condicional somente se expandedIndex for null */}
        {expandedIndex === null && (
          <div className="description-preview">
            <div className="description-preview-space"></div>
            <div className="decription-preview-content-v2">
              <div className="decription-preview-content-v2-p">
                {item.description.main}
              </div>
              <SimpleGraph
                atlist={item.production_activities}
                valueObject={item}
              ></SimpleGraph>
            </div>
          </div>
        )}
      </div>
    ))}
  </div>);
};


  export default IndicatorInfo
  