import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavbarDesktop.css";
import searchicondesktop from "../../img/search_desktop.svg";
import dpp from "../../img/dpp_logo_desktop.svg";
import LanguageSelection from "./LanguageSelection";

const NavbarDesktop = () => {
  // Obtém a rota atual
  const currentLocation = useLocation();

  // Verifica a rota atual
  const isProductsRoute = currentLocation.pathname === "/view";
  const isAboutRoute = currentLocation.pathname === "/about";
  const isSearchRoute = currentLocation.pathname === "/search";
  const languages = ["EN", "PT", "DE", "ES", "FR"];

  return (
    <div className="navbar-desktop">
      <div className="navbar-left-desktop">
        <NavLink to="/" className="navbar-item-desktop">
          <span className="text-navbardesktop">About Us</span>
          {isAboutRoute && (
            <div className="text-underline-navbardesktop"> </div>
          )}
        </NavLink>
        <div className="separator-navbar-line"></div>
        <NavLink to="/view" className="navbar-item-desktop">
          <span className="text-navbardesktop">Products </span>
          {isProductsRoute && (
            <div className="text-underline-navbardesktop"> </div>
          )}
        </NavLink>
      </div>
      <div className="navbar-center-desktop">
        <a href="/" className="navbar-item-center-desktop">
          <img src={dpp} alt="Ícone"></img>
        </a>
      </div>
      <div className="navbar-right-desktop">
        <NavLink to="/search"   className={`navbar-item-desktop-search ${isSearchRoute ? "active" : ""}`} >
          <img
            className={`icon-search-desktop ${isSearchRoute ? "active" : ""}`}
            src={searchicondesktop}
            alt="Ícone"
          ></img>
        </NavLink>
        <LanguageSelection languages={languages}></LanguageSelection>
      </div>
    </div>
  );
};

export default NavbarDesktop;
