import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import _, { debounce, throttle } from "lodash";
import "./Product.css";
import ProductHeaderV2 from "../../components/v2/ProductHeaderV2";
import ProductGallery from "../../components/v2/product/ProductGallery";
import ScoreInfo from "../../components/v2/ScoreInfo";
import ScoreVertical from "../../components/v2/ScoreVertical";
import IndicatorInfo from "../../components/v2/IndicatorInfo";
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import GeoMap from "../../components/v2/GeoMap";
import Journey from "../../components/v2/Journey";
import AditionalInfo from "../../components/v2/Aditionalinfo.jsx";
import Properties from "../../components/v2/Properties";
import BillOfMaterials from "../../components/v2/BillOfMaterials";
import CareInstructions from "../../components/v2/CareInstructions";
import MaintenanceDiscard from "../../components/v2/MaintenanceDiscard";
import NavbarDesktop from "../../components/desktop/NavbarDesktop";
import CompositionGraph from "../../components/v2/CompositionGraph.jsx";
import CertificationsDesktop from "../../components/desktop/CertificationsDesktop";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Fab } from "@mui/material";
//imports dos jsons para mapear tradução
import countries from "./jsons/countries.json";
import colors from "./jsons/colors.json";
import fashions from "./jsons/fashion.json";
import collections from "./jsons/collection.json";
import fits from "./jsons/fit.json";
import seasons from "./jsons/seasons.json";
import product_type from "./jsons/type.json";
import SimilarProducts from "../../components/v2/SimilarProducts";
//Icones corrigidos
import BillOfMaterialsIcon from "../../img/BillOfMaterials.svg";
import CareInstructionsIcon from "../../img/CareInstructions.svg";
import PropertiesIcon from "../../img/Properties.svg";
import RepairDiscardIcon from "../../img/RepairDiscard.svg";
import DppLogoDesktopProduct from "../../img/dpplogoproductdesktop.svg";

//pagina para mostrar o produto e todas as informações do mesmo
//será esta a pagina que recebe todos os componentes
//contem função para verificar a existencia do produto a apresentar
const Product = ({ productList }) => {
  const [queryParameters] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const touchAreaRef = useRef(null);
  const billOfMaterialsRef = useRef(null);
  const navigate = useNavigate();
  const [showSimilarProductsDesktop, setshowSimilarProductsDesktop] =
    useState(false);
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });
  //num max de paginas
  const maxPages = 5;

  const goToPage = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageUp = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage - 1;
      return newPage >= 0 ? newPage : 0;
    });
  };
  const handlePageDown = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + 1;
      return newPage < maxPages ? newPage : maxPages - 1;
    });
  };

  // scroll a paginas do conteudo do lado direito
  const containerRef = useRef(null);

  useEffect(() => {
    const preventPullToRefresh = (e) => {
      if (e.touches.length > 1) {
        // If there are more than one touches, it's a multi-touch gesture, so we ignore it.
        return;
      }
      if (e.touches[0].clientY > 0) {
        // Prevent the pull-to-refresh action
        e.preventDefault();
      }
    };

    // Add the event listener
    document.addEventListener("touchmove", preventPullToRefresh, {
      passive: false,
    });

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("touchmove", preventPullToRefresh);
    };
  }, []);

  // Debounce function for handling scroll events
  const debouncedHandleWheel = _.debounce((event) => {
    const rightContainer = containerRef.current;
    if (!rightContainer) return;

    const isScrollingDown = event.deltaY > 0;
    const currentScrollTop = rightContainer.scrollTop;

    if (rightContainer.scrollHeight > rightContainer.clientHeight) {
      if (
        isScrollingDown &&
        currentScrollTop + rightContainer.clientHeight >=
          rightContainer.scrollHeight - 1
      ) {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, 3));
        event.preventDefault(); // Prevent the default scroll
        /* console.log("Page changed: Scroll down"); */
      } else if (!isScrollingDown && currentScrollTop === 0) {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
        event.preventDefault(); // Prevent the default scroll
        /* console.log("Page changed: Scroll up"); */
      }
    } else {
      if (isScrollingDown) {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, 3));
        event.preventDefault(); // Prevent the default scroll
        /* console.log("Page changed: Scroll down"); */
      } else {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
        event.preventDefault(); // Prevent the default scroll
        /* console.log("Page changed: Scroll up"); */
      }
    }
  }, 100);

  useEffect(() => {
    const rightContainer = containerRef.current;

    if (rightContainer) {
      rightContainer.addEventListener("wheel", debouncedHandleWheel);
    }

    return () => {
      if (rightContainer) {
        rightContainer.removeEventListener("wheel", debouncedHandleWheel);
      }
    };
  }, [debouncedHandleWheel]);

  // Effect to reset scroll position when the page changes
  useEffect(() => {
    const rightContainer = containerRef.current;

    if (rightContainer) {
      // Reset scroll to the top when the page changes
      rightContainer.scrollTop = 0;
      console.log("Scroll position reset to top on page change");
    }
  }, [currentPage]);

  /* console.log(currentPage); */

  useEffect(() => {
    // Verifica se o objeto matchMedia está disponível
    if (typeof window === "undefined") {
      return;
    }
    const mediaQueryList = window.matchMedia("(max-width: 768px)");
    // Função para atualizar o estado com base na mudança da condição de mídia
    const handleChange = (e) => {
      setIsMobile(e.matches);
    };
    // Adiciona o ouvinte para mudanças
    mediaQueryList.addEventListener("change", handleChange);
    // Verifica a condição inicial
    handleChange(mediaQueryList);
    // Função de limpeza para remover o ouvinte
    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, []);

  const handleBackClick = () => {
    navigate("/view");
  };
  const handleMainpage = () => {
    setCurrentPage(0);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleOpenMenu = () => {
    setMenuOpen(true);
  };
  const handleCloseMenu = () => {
    setMenuOpen(false);
  };
  const carouselRef = useRef();
  const handleNextClick = () => {
    carouselRef.current.goToNextSlide();
  };
  const handlePrevClick = () => {
    carouselRef.current.goToPrevSlide();
  };
  const handlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePrevClick,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  //PAGINA 5
  const [currentVisibleSlide, setCurrentVisibleSlide] = useState(1);
  //-----------------VERIFICAÇÕES--------------------
  const getCountryDescription = (code) => {
    const country = countries.find((country) => country.code === code);
    return country ? country.description.en : "Unknown Country";
  };
  const getColorDescription = (code) => {
    const color = colors.find((color) => color.code === code);
    return color ? color.description.en : "-";
  };
  const getFashionDescription = (code) => {
    const fashion = fashions.find((fashion) => fashion.code === code);
    return fashion ? fashion.description.en : "-";
  };
  const getFitsDescription = (code) => {
    const fit = fits.find((fit) => fit.code === code);
    return fit ? fit.description.en : "-";
  };
  const getProductTypeDescription = (code) => {
    for (const type of product_type) {
      const product_type_item = type.options.find(
        (product_type_item) => product_type_item.code === code
      );
      if (product_type_item) {
        return product_type_item.description.en;
      }
    }
    return "-";
  };
  const getSeasonsDescription = (code) => {
    const season = seasons.find((season) => season.code === code);
    return season ? season.description.en : "-";
  };
  const getCollectionDescription = (code) => {
    const collection = collections.find(
      (collection) => collection.code === code
    );
    return collection ? collection.description.en : "-";
  };
  //-------------FIM DAS VERIFICAÇÕES----------------

  const handleToggleMap = () => {
    setIsMapExpanded(!isMapExpanded);
  };

  useEffect(() => {
    if (!isMobile) return; // Only run effect if isMobile is true

    const handleTouchMove = (e) => {
      e.preventDefault(); // Prevent default scroll handling
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: true });
    /* console.log("piuuu"); */

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isMobile]); // Add isMobile as a dependency

  const [startY, setStartY] = useState(0);
  const swipeThreshold = 70; // Adjust this threshold as needed

  useEffect(() => {
    // Simulate loading time, replace with your actual loading logic
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 0);
    return () => clearTimeout(timeout); // Cleanup on component unmount
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Eventualmente adicionar para uma pagina de Loading...
  }
  // Procura o produto na lista pelo seu ID
  const product = productList.find(
    (p) => p.id === queryParameters.get("productId")
  );
  // Se o produto não existir, dá return à pagina NotFound
  if (!product) {
    return <Navigate to="/error" replace />;
  }
  const returnToMainPage = () => {
    setCurrentPage(0); // define a pagina para a primeira (main product)
  };

  const handleTouchStart = (event) => {
    if (!isMobile) return; // Only run if isMobile is true
    setStartY(event.touches[0].clientY);
  };

  const handleSimilarProductsClick = () => {
    setshowSimilarProductsDesktop(true);
  };

  const handleTouchMove = (event) => {
    if (!isMobile || !startY) return; // Only run if isMobile is true and startY is set
    const deltaY = event.touches[0].clientY - startY;
    const billOfMaterials = billOfMaterialsRef.current;
    const touchArea = touchAreaRef.current;

    // Check if the touch is within the billOfMaterials
    if (billOfMaterials && billOfMaterials.contains(event.target)) {
      const scrollTop = billOfMaterials.scrollTop;
      const scrollHeight = billOfMaterials.scrollHeight;
      const clientHeight = billOfMaterials.clientHeight;
      if (deltaY < 0 && scrollTop + clientHeight < scrollHeight) {
        // Scroll down in BOM
        return;
      } else if (deltaY > 0 && scrollTop > 0) {
        // Scroll up in BOM
        return;
      }
    }

    // Prevent swipes within touchArea
    if (touchArea && touchArea.contains(event.target)) {
      return;
    }

    // Handle swipe actions
    if (Math.abs(deltaY) > swipeThreshold) {
      if (deltaY < 0) {
        handlePageDown();
      } else {
        handlePageUp();
      }
      setStartY(0); // Reset startY after handling the swipe
    }
  };

  const handleTouchMoveDebounced = debounce(handleTouchMove, 10);

  const handleTouchEnd = () => {
    if (!isMobile) return; // Only run if isMobile is true
    setStartY(0); // Reset startY on touch end
  };

  const getPageTitle = (currentPage) => {
    switch (currentPage) {
      case 1:
        return "Index";
      case 2:
        return "Detailed Index";
      case 3:
        return "The Journey";
      case 4:
        return "Information";
      default:
        return "";
    }
  };
  const pageTitle = getPageTitle(currentPage);
  let pageContent;
  const dataDetailedIndex = [
    {
      id: 0,
      title: "Water Consumption",
      description: {
        indicator:
          product.dpp.product_info.detailed_index.water_consumption.value +
          " litres of water were used to produce this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total litres of water consumed in the production of this article.",
      },
      icon: "./images/icon_water_v2.svg",
      value: product.dpp.product_info.detailed_index.water_consumption.value,
      units: product.dpp.product_info.detailed_index.water_consumption.units,
      scale: product.dpp.product_info.detailed_index.water_consumption.scale,
      production_activities:
        product.dpp.product_info.detailed_index.water_consumption
          .production_activities,
    },
    {
      id: 1,
      title: "Carbon Footprint",
      description: {
        indicator:
          product.dpp.product_info.detailed_index.carbon_footprint.value +
          " kg of CO2 were produced to produce this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total carbon footprint produced in the production of this article.",
      },
      icon: "./images/icon_co2_v2.svg",
      value: product.dpp.product_info.detailed_index.carbon_footprint.value,
      units: product.dpp.product_info.detailed_index.carbon_footprint.units,
      scale: product.dpp.product_info.detailed_index.carbon_footprint.scale,
      production_activities:
        product.dpp.product_info.detailed_index.carbon_footprint
          .production_activities,
    },
    {
      id: 2,
      title: "Chemical Consumption",
      description: {
        indicator:
          product.dpp.product_info.detailed_index.chemical_products.value +
          " grams of chemicals were used to produce this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total chemicals used in the production of this article.",
      },
      icon: "./images/icon_chemical_v2.svg",
      value: product.dpp.product_info.detailed_index.chemical_products.value,
      units: product.dpp.product_info.detailed_index.chemical_products.units,
      scale: product.dpp.product_info.detailed_index.chemical_products.scale,
      production_activities:
        product.dpp.product_info.detailed_index.chemical_products
          .production_activities,
    },
    {
      id: 3,
      title: "Recovered Waste",
      description: {
        indicator:
          product.dpp.product_info.detailed_index.recovered_waste.value +
          " % of recovered waste in the production of this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total recovered waste in the production of this article.",
      },
      icon: "./images/icon_recovered_waste_v2.svg",
      value: product.dpp.product_info.detailed_index.recovered_waste.value,
      units: product.dpp.product_info.detailed_index.recovered_waste.units,
      scale: product.dpp.product_info.detailed_index.recovered_waste.scale,
      production_activities:
        product.dpp.product_info.detailed_index.recovered_waste
          .production_activities,
    },
  ];
  const data = [
    {
      image: PropertiesIcon,
      text: "Properties",
      id: 0,
    },
    {
      image: CareInstructionsIcon,
      text: "Care Instructions",
      id: 1,
    },
    {
      image: RepairDiscardIcon,
      text: "Repair and Discard",
      id: 2,
    },
    {
      image: BillOfMaterialsIcon,
      text: "Bill of Materials",
      id: 3,
    },
  ];
  const weight = product.dpp.product_info.properties.weight;
  const displayWeight = weight !== null ? weight + " kg" : "-";
  const categoriesData = [
    {
      name: "Type of Product",
      description: getProductTypeDescription(
        product.dpp.product_info.properties.type
      ),
    },
    {
      name: "Fit",
      description: getFitsDescription(product.dpp.product_info.properties.fit),
    },
    {
      name: "Category",
      description: getCollectionDescription(
        product.dpp.product_info.properties.collection
      ),
    },
    {
      name: "Fashion",
      description: getFashionDescription(
        product.dpp.product_info.properties.fashion
      ),
    },
    {
      name: "Color",
      description:
        product.dpp.product_info.properties.color &&
        product.dpp.product_info.properties.color.length > 0
          ? product.dpp.product_info.properties.color
              .map((color) => getColorDescription(color))
              .join(", ")
          : "-",
    },
    {
      name: "Colection",
      description:
        product.dpp.product_info.properties.season &&
        product.dpp.product_info.properties.season.length > 0
          ? product.dpp.product_info.properties.season
              .map((season) => getSeasonsDescription(season))
              .join(", ")
          : "-",
    },
    {
      name: "Weight",
      description: displayWeight,
    },
    {
      name: "Variants",
      description:
        product.dpp.product_info.properties.number_of_variants || "-",
    },
    {
      name: "Reciclability",
      description:
        product.dpp.product_info.properties.reciclability !== null
          ? product.dpp.product_info.properties.reciclability
            ? "Yes"
            : "No"
          : "-",
    },
    {
      name: "Microplastics",
      description:
        product.dpp.product_info.properties.microplastics !== null
          ? product.dpp.product_info.properties.microplastics
            ? "Yes"
            : "No"
          : "-",
    },
    {
      name: "Dangerous Chemicals",
      description:
        product.dpp.product_info.properties.dangerous_chemicals !== null
          ? product.dpp.product_info.properties.dangerous_chemicals
            ? "Yes"
            : "No"
          : "-",
    },
  ];
  const longtext_title = "";

  if (currentPage === 0 && isMobile) {
    pageContent = (
      <div className="page1-container-v2">
        <div className="header-content-v2">
          <img
            src="./images/icon_arrow_back.svg"
            alt="product-arrow-goback"
            className="product-arrow-goback"
            onClick={handleBackClick}
          />
          <div className="header-content-v2-content">
            <div className="header-content-v2-title">
              {product.dpp.product_info.name}
            </div>
            <div className="header-content-v2-text">
              {product.dpp.product_info.brand} · Made In{" "}
              {getCountryDescription(product.dpp.product_info.made_in)}
            </div>
          </div>
          <img
            src="./images/icon_interrogation.svg"
            alt="product-interrogation"
            className="product-interrogation"
          />
        </div>
        <div className="product-maindiv-container-v2">
          <ProductGallery
            recycled={product.dpp.product_info.composition.recycled}
            compositionList={product.dpp.product_info.composition.fibers}
            certifications={product.dpp.product_info.certifications}
            productImgs={product.dpp.product_info.img}
            touchAreaRef={touchAreaRef}
            handleNavigation={returnToMainPage}
            product_info={product.dpp.product_info}
            score={product.dpp.product_info.score}
          />
        </div>
        <div className="product-maindiv-score-v2">
          <ScoreInfo
            currentPage={0}
            icon={"./images/icon_AF.svg"}
            title={"Index"}
            description={""}
            score={product.dpp.product_info.score}
          />
        </div>
        <div className="icon-arrows-div">
          <img
            src="./images/icon_swipe_arrows.svg"
            alt="icon-arrows"
            className="icon-arrows"
          />
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 0 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div" ref={containerRef}>
            <div className="product-maindiv-score-v2-desktop">
              <div className="box-score-container-v2-desktop">
                <ScoreInfo
                  currentPage={0}
                  icon={"./images/icon_AF.svg"}
                  title={"Index"}
                  description={""}
                  score={product.dpp.product_info.score}
                />
                <div className="title-product-maindiv-score-v2-desktop">
                  {" "}
                  <a className="click-to-see-dpp-product"  onClick={() => goToPage(1)}> Faça scroll para ver o nosso </a>
                  <img
                    src={DppLogoDesktopProduct}
                    alt="icon-arrows"
                    className="img-product-maindiv-score-v2-desktop"
                  />
                </div>
              </div>
            </div>
            <div className="certifications-container-desktop">
              <CertificationsDesktop
                certifications={product.dpp.product_info.certifications}
              ></CertificationsDesktop>
            </div>
            <div className="composition-desktop">
              <CompositionGraph
                compositionList={product.dpp.product_info.composition.fibers}
                recycled={product.dpp.product_info.composition.recycled}
              />
            </div>
            <div className="additional-info-container-desktop">
              <div className="additional-info-product-container">
                <AditionalInfo
                  data={data}
                  currentVisibleSlide={currentVisibleSlide}
                  setCurrentVisibleSlide={setCurrentVisibleSlide}
                  refNextBack={carouselRef}
                />
              </div>
              <div className="product-maindiv-page1-v2-desktop" {...handlers}>
                <div className="container-arrows-content-desktop-product">
                  <Fab
                    className="material-button-left"
                    size="small"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    onClick={() => carouselRef.current.goToPrevSlide()}
                  >
                    <KeyboardArrowLeft style={{ fontSize: 20 }} />
                  </Fab>
                  {currentVisibleSlide === 0 && (
                    <div className="properties">
                      {<Properties categories={categoriesData} />}
                    </div>
                  )}
                  {currentVisibleSlide === 1 && (
                    <div className="care-instructions-products">
                      {
                        <CareInstructions
                          care_instructions={
                            product.dpp.product_info.care_instructions
                          }
                        />
                      }
                    </div>
                  )}
                  {currentVisibleSlide === 2 && (
                    <div className="maintenance-discard">
                      {
                        <MaintenanceDiscard
                          longtext_text={
                            product.dpp.product_info
                              .maintenance_repair_instructions
                          }
                          longtext_title={longtext_title}
                        />
                      }
                    </div>
                  )}
                  {currentVisibleSlide === 3 && (
                    <div className="bill-of-materials" ref={billOfMaterialsRef}>
                      {
                        <BillOfMaterials
                          data={product.dpp.product_info.bill_of_materials}
                        ></BillOfMaterials>
                      }
                    </div>
                  )}
                  <Fab
                    className="material-button-right"
                    size="small"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    onClick={() => carouselRef.current.goToNextSlide()}
                  >
                    <KeyboardArrowRight style={{ fontSize: 20 }} />
                  </Fab>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 1 && isMobile) {
    pageContent = (
      <div className="page2-container-v2">
        <div className="product-maindiv-page">
          <div className="div-score-sac-ses-v2">
            <ScoreInfo
              currentPage={1}
              icon={"./images/icon_AF.svg"}
              title={"Index"}
              description={
                "The product's overall index considering the 4 dimensions of sustainability (environmental, circular, economical and social). A higher rating represents a more sustainable product."
              }
              score={product.dpp.product_info.score}
            />
            <ScoreInfo
              currentPage={1}
              icon={"./images/icon_sac_v2.svg"}
              title={"Environmental and Circularity"}
              description={
                "Classification based on the product's performance in environmental and circular aspects."
              }
              score={product.dpp.product_info.sac}
            />
            <ScoreInfo
              currentPage={1}
              icon={"./images/icon_ses_v2.svg"}
              title={"Economical and Social"}
              description={
                "Classification based on the economical and social performance of the value chain."
              }
              score={product.dpp.product_info.ses}
            />
          </div>
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 1 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div" ref={containerRef}>
            <div className="div-score-sac-ses-v2">
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_AF.svg"}
                title={"Index"}
                description={
                  "The product's overall index considering the 4 dimensions of sustainability (environmental, circular, economical and social). A higher rating represents a more sustainable product."
                }
                score={product.dpp.product_info.score}
              />
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_sac_v2.svg"}
                title={"Environmental and Circularity"}
                description={
                  "Classification based on the product's performance in environmental and circular aspects."
                }
                score={product.dpp.product_info.sac}
              />
              <ScoreInfo
                currentPage={1}
                icon={"./images/icon_ses_v2.svg"}
                title={"Economical and Social"}
                description={
                  "Classification based on the economical and social performance of the value chain."
                }
                score={product.dpp.product_info.ses}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 2 && isMobile) {
    pageContent = (
      <div className="page3-container">
        <div className="div-journey-v2">
          <IndicatorInfo data={dataDetailedIndex} />
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 2 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div" ref={containerRef}>
            <div className="div-journey-v2">
              <IndicatorInfo data={dataDetailedIndex} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 3 && isMobile) {
    pageContent = (
      <div className="page4-container">
        <div className="product-maindiv-page4-v2">
          <GeoMap
            journeyTotal_distance={
              product.dpp.product_info.journey.total_distance
            }
            journeyTotal_countries={
              product.dpp.product_info.journey.total_countries
            }
            journeyActivities={
              product.dpp.product_info.journey.production_activities
            }
            isExpanded={isMapExpanded}
            onToggleMap={handleToggleMap}
            touchAreaRef={touchAreaRef}
          />
          {!isMapExpanded && (
            <div className="product-div-journey">
              <Journey
                steps={product.dpp.product_info.journey.production_activities}
              />
            </div>
          )}
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 3 && !isMobile) {
    pageContent = (
      <div className="page1-container-v2-desktop">
        <div className="page-products-navbar">
          <NavbarDesktop></NavbarDesktop>
        </div>
        <div className="product-maindiv-container-v2-desktop">
          <div className="product-rightside-main-div" ref={containerRef}>
            <div className="product-maindiv-page4-v2 desktop">
              <GeoMap
                journeyTotal_distance={
                  product.dpp.product_info.journey.total_distance
                }
                journeyTotal_countries={
                  product.dpp.product_info.journey.total_countries
                }
                journeyActivities={
                  product.dpp.product_info.journey.production_activities
                }
                isExpanded={isMapExpanded}
                onToggleMap={handleToggleMap}
                touchAreaRef={touchAreaRef}
              />
              {!isMapExpanded && (
                <div className="product-div-journey">
                  <Journey
                    steps={
                      product.dpp.product_info.journey.production_activities
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (currentPage === 4 && isMobile) {
    pageContent = (
      <div className="page5-container">
        <div className="product-maindiv-page5-v2-carousel">
          <AditionalInfo
            data={data}
            currentVisibleSlide={currentVisibleSlide}
            setCurrentVisibleSlide={setCurrentVisibleSlide}
            refNextBack={carouselRef}
          />
        </div>
        <div className="product-maindiv-page5-v2" {...handlers}>
          {currentVisibleSlide === 0 && (
            <div className="properties">
              {<Properties categories={categoriesData} />}
            </div>
          )}
          {currentVisibleSlide === 1 && (
            <div className="care-instructions-products">
              {
                <CareInstructions
                  care_instructions={product.dpp.product_info.care_instructions}
                />
              }
            </div>
          )}
          {currentVisibleSlide === 2 && (
            <div className="maintenance-discard">
              {
                <MaintenanceDiscard
                  longtext_text={
                    product.dpp.product_info.maintenance_repair_instructions
                  }
                  longtext_title={longtext_title}
                />
              }
            </div>
          )}
          {currentVisibleSlide === 3 && (
            <div className="bill-of-materials" ref={billOfMaterialsRef}>
              {
                <BillOfMaterials
                  data={product.dpp.product_info.bill_of_materials}
                ></BillOfMaterials>
              }
            </div>
          )}
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
        <div
          className="SimilarProducts"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMoveDebounced}
          onTouchEnd={handleTouchEnd}
          ref={touchAreaRef}
        >
          <SimilarProducts
            productList={product.dpp.product_info.similar_products}
            handleMainpage={handleMainpage}
          />
        </div>
      </div>
    );
  }
  return isMobile ? (
    <div className="product-fixed-page">
      <div className="product-container">
        <div className="page-indicator">
          {Array.from({ length: maxPages }, (_, index) => (
            <div
              key={index}
              className={`indicator-dot ${
                index === currentPage ? "active" : ""
              }`}
            ></div>
          ))}
        </div>
        {currentPage !== 0 && (
          <>
            <div className="header-content-v2">
              <img
                src="./images/icon_arrow_back.svg"
                alt="product-arrow-goback"
                className="product-arrow-goback"
                onClick={handleBackClick}
              />
              <div className="header-content-v2-content center">
                <div className="header-content-v2-title center">
                  {pageTitle}
                </div>
              </div>
              <img
                src="./images/icon_interrogation.svg"
                alt="product-interrogation"
                className="product-interrogation"
              />
            </div>
            <div className="header-v2">
              <ProductHeaderV2
                handleNavigation={returnToMainPage}
                product_info={product.dpp.product_info}
                score={product.dpp.product_info.score}
              />
              <ScoreVertical score={product.dpp.product_info.score} />
            </div>
          </>
        )}
        <div
          className="swipe-main-container"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMoveDebounced}
          onTouchEnd={handleTouchEnd}
        >
          {pageContent}
        </div>
      </div>
    </div>
  ) : (
    <div className="main-container-desktop">
      <div className="gallery-container-desktop">
        <div className="header-container-v2-desktop">
          <div className="header-content-v2-content-desktop">
            <div className="header-content-v2-title-desktop">
              {product.dpp.product_info.name}
            </div>
            <div className="header-content-v2-text-desktop">
              {product.dpp.product_info.brand}
            </div>
          </div>
          <div className="brand-container-v2-desktop">
            <div className="made-in-content-v2-text-desktop">
              {
                <div className="text-inline-desktop">
                  Made In&nbsp;
                  <span style={{ display: "inline", marginLeft: "1px" }}>
                    {getCountryDescription(product.dpp.product_info.made_in)}
                  </span>{" "}
                </div>
              }
            </div>{" "}
          </div>
        </div>
        <ProductGallery
          recycled={product.dpp.product_info.composition.recycled}
          compositionList={product.dpp.product_info.composition.fibers}
          certifications={product.dpp.product_info.certifications}
          productImgs={product.dpp.product_info.img}
          touchAreaRef={touchAreaRef}
        />
      </div>
      <div className="content-container-desktop">{pageContent}</div>
      <div className="dot-container">
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentPage ? "active" : ""}`}
            onClick={() => goToPage(index)}
          />
        ))}
      </div>
      {/*       <div className="arrow-up-similar-products">
      <img
                src="./images/icon_swipe_arrows.svg"
                alt="icon-arrows"
                className="icon-arrows-desktop"
                onClick={handleSimilarProductsClick}
              />

      </div> */}

      {
        <SimilarProducts
          productList={product.dpp.product_info.similar_products}
          handleMainpage={handleMainpage}
        />
      }
    </div>
  );
};
export default Product;
