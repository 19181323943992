// ItemIndicator.js
import React, { useState, useEffect } from "react";
import "./ItemIndicatorInfo.css";
import IndicatorWeight from "./IndicatorWeight";
import ComplexGraph from "./ComplexGraph";

const ItemIndicatorInfo = ({
  item,
  index,
  expandedIndex,
  handleItemClick,
  atlist,
  valueObject,
}) => {
  const [iconID, setIconID] = useState();
  const isExpanded = expandedIndex === index;
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });


  useEffect(() => {
    if (item.id === 0) {
      setIconID("./images/icon_water_indicator_v2.svg");
    } else if (item.id === 1) {
      setIconID("./images/icon_co2_indicator_v2.svg");
    } else if (item.id === 2) {
      setIconID("./images/icon_chemical_indicator_v2.svg");
    } else if (item.id === 3) {
      setIconID("./images/icon_recovered_waste_indicator_v2.svg");
    }
  }, [item.id]);

  return isMobile ? (
    <div
      className={`item-indicatorinfo ${isExpanded ? "expanded" : "collapsed"}`}
    >
      <div
        className={`content-indicatorinfo ${
          isExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="item-info">
          <div className="icon-title">
            <img className="icon-index" alt="icon-index" src={item.icon} />
            <div
              onClick={() => handleItemClick(index)}
              className={`title-detailedIndex ${!isExpanded ? "" : "expanded"}`}
            >
              <div
                className={`title-detailedIndex-text ${
                  !isExpanded ? "" : "expanded"
                }`}
              >
                {" "}
                {item.title}
              </div>
              {expandedIndex !== null && !isExpanded && (
                <p className="value">
                  {item.value} {item.units}
                </p>
              )}
              <span
                className={`journey-map-step-plus ${
                  isExpanded ? "expanded" : ""
                }`}
              >
                <span className="plus-sign">+</span>
              </span>
            </div>
          </div>
        </div>

        {isExpanded && (
          <div
            className={`extra-content ${isExpanded ? "expanded" : "collapsed"}`}
          >
            <p className="indicator-description">
              {item.description.indicator}{" "}
            </p>
            <ComplexGraph
              atlist={atlist}
              valueObject={valueObject}
            ></ComplexGraph>
            <p className="indicator-description">
              {item.description.complexgraph}{" "}
            </p>
            <IndicatorWeight
              icon={iconID}
              levelselected={item.scale}
            ></IndicatorWeight>
          </div>
        )}
      </div>
    </div>
  ) : (    <div
    className={`item-indicatorinfo ${isExpanded ? "expanded" : "collapsed"}`}
  >
    <div
      className={`content-indicatorinfo ${
        isExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="item-info">
        <div className="icon-title">
          <img className="icon-index" alt="icon-index" src={item.icon} />
          <div
            onClick={() => handleItemClick(index)}
            className={`title-detailedIndex ${!isExpanded ? "" : "expanded"}`}
          >
            <div
              className={`title-detailedIndex-text ${
                !isExpanded ? "" : "expanded"
              }`}
            >
              {" "}
              {item.title}
            </div>
            {expandedIndex !== null && !isExpanded && (
              <p className="value">
                {item.value} {item.units}
              </p>
            )}
            <span
              className={`journey-map-step-plus ${
                isExpanded ? "expanded" : ""
              }`}
            >
              <span className="plus-sign">+</span>
            </span>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div
          className={`extra-content ${isExpanded ? "expanded" : "collapsed"}`}
        >
          <p className="indicator-description">
            {item.description.indicator}{" "}
          </p>
          <ComplexGraph
            atlist={atlist}
            valueObject={valueObject}
          ></ComplexGraph>
          <p className="indicator-description">
            {item.description.complexgraph}{" "}
          </p>
          <IndicatorWeight
            icon={iconID}
            levelselected={item.scale}
          ></IndicatorWeight>
        </div>
      )}
    </div>
  </div>);
};

export default ItemIndicatorInfo;
