import React, { useState, useEffect } from 'react';
import "./SearchBox.css";

const SearchBox = ({productId,handleInputChange,setShowQrReader }) => {


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
 
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
 
  useEffect(() => {
    window.addEventListener('resize', handleResize);
 
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div>
      {isMobile ? (
    <div className='searchbox-v2 '>
    <div className="searchbox-v2-input-component">
    <img
      className="icon-searchboxinput-v2"
      alt="icon-input"
      src="./images/search_lupa.svg"
    />
    <input
      className="searchboxinput-value-v2"
      placeholder="Search by your Product ID"
      value={productId}
      onChange={handleInputChange}

    />
        <img
      className="icon-searchboxinput-qr-v2"
      alt="icon-searchinput-qr-v2"
      src="./images/icon_qr_v2.svg"
      onClick={() => setShowQrReader((prev) => !prev)}
    
    />

  </div>
  </div>
      ) : (
        <div className='searchbox-v2 '>
        <div className="searchbox-v2-input-component-desktop">
        <img
          className="icon-searchboxinput-v2-desktop"
          alt="icon-input"
          src="./images/search_lupa.svg"
        />
        <input
          className="searchboxinput-value-v2-desktop"
          placeholder="Search by your Product ID"
          value={productId}
          onChange={handleInputChange}
    
        />
    
      </div>
      </div>
      )}
</div>

    

  )
}

export default SearchBox
