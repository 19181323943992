import React, { useState, useEffect, useRef } from "react";
import "./Products.css";

// Import components
import ProductItem from "../../components/v2/ProductItemProducts";
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import FiltersComponent from "../../components/v2/Filters";
import NavbarDesktop from "../../components/desktop/NavbarDesktop";
import FooterDesktop from "../../components/desktop/FooterDesktop";

// Icons
import ProductsView from "../../img/products-view.svg";
import ProductsViewSecond from "../../img/products-view-second.svg";
import Filters from "../../img/filters.svg";
import FiltersClose from "../../img/filters-close.svg";

// Importar Product-type
import product_type from "./jsons/type.json";

const Products = ({ productList }) => {
  const [viewInLine, setViewInLine] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const productItemsRef = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  // Para o componente Filters
  const [productId, setProductId] = useState("");
  const [brands, setBrands] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [ScoreRange, setScoreRange] = useState([0, 100]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handlePageShow = () => {
      const scrollableElement = productItemsRef.current;

      if (scrollableElement) {
        scrollableElement.classList.add("scrollable");
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  const handleClickFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleCloseMenuApplyFilters = (menuclose) => {
    setShowFilters(!showFilters);
  };

  const filteredProducts = hasFilters
    ? productList.filter((product) => {
      
        const brandCondition =
          selectedBrands.length === 0 || selectedBrands.includes(product.brand);
        const scoreCondition =
          product.score >= ScoreRange[0] && product.score <= ScoreRange[1];
        const typeCondition =
          selectedTypes.length === 0 || selectedTypes.includes(product.code);
        const searchBoxCondition = product.id
          .toString()
          .toLowerCase()
          .includes(productId.toLowerCase());
        return (
          scoreCondition &&
          brandCondition &&
          typeCondition &&
          searchBoxCondition
        );
      })
    : productList.filter((product) => {
        // Segunda condição: verifica se o productId é uma string vazia ou se está contido no ID do produto

        return (
          productId === "" ||
          product.id.toString().toLowerCase().includes(productId.toLowerCase())
        );
      });

  // Sorteia por score
  filteredProducts.sort((a, b) => b.score - a.score);

  /*
  const filteredProducts = productList.filter(product =>
    productId === "" || product.id.toString().toLowerCase().includes(productId.toLowerCase())
  ); */

  //Obtem os seleccionados da Filters
  const handleGetFilters = (selectedTypes, convertedRange, selectedBrands) => {
    //console.log("Filters selected in parent:", { selectedTypes, convertedRange, selectedBrands });
    setHasFilters(true);
    setScoreRange(convertedRange);
    setSelectedBrands(selectedBrands);
    setSelectedTypes(selectedTypes);

    //console.log("score range" + ScoreRange)
    //console.log("hasfilters" + hasFilters)
  };

  //console.log(productList);

  const handleClickOutside = (ClickOutside) => {
    setShowFilters(ClickOutside);
  };

  useEffect(() => {
    const uniqueBrands = [
      ...new Set(productList.map((product) => product.brand)),
    ];
    setBrands(uniqueBrands);

    const allAssociations = [];
    product_type.forEach((category) => {
      // allAssociations.push({ code: category.code, description: category.description.en });
      category.options.forEach((option) => {
        allAssociations.push({
          code: option.code,
          description: option.description.en,
        });
      });
    });
    /* console.log(brands); */
    setDescriptions(allAssociations);
    /* console.log("Product List" + JSON.stringify(productList)) */
  }, [productList, product_type]);

  return isMobile ? (
    <div className="page-products-container">
      <div className="top-info">
        <div className="title">Products</div>
        <div className="filters-and-group-by">
          <div className="filters" onClick={handleClickFilters}>
            Filters
            <img src={showFilters ? FiltersClose : Filters} alt="Filter Icon" />
          </div>
          <div className="group-by">
            <div
              className={`icon ${!viewInLine ? "active" : ""}`}
              onClick={() => setViewInLine(false)}
            >
              <img src={ProductsView} alt="Grid View" />
            </div>
            <div
              className={`icon-second ${viewInLine ? "active" : ""}`}
              onClick={() => setViewInLine(true)}
            >
              <img src={ProductsViewSecond} alt="List View" />
            </div>
          </div>
        </div>
        {showFilters && (
          <FiltersComponent
            brands={brands}
            descriptions={descriptions}
            onFiltersChange={handleGetFilters}
            onApplyFilters={handleCloseMenuApplyFilters}
            initialSelectedTypes={selectedTypes}
            initialScoreRange={ScoreRange}
            initialSelectedBrands={selectedBrands}
            onClickOutside={handleClickOutside}
          />
        )}
      </div>
      <div
        className={
          viewInLine ? "page-products-content-view" : "page-products-content"
        }
      >
        <div
          className={
            viewInLine ? "page-product-items-inline" : "page-product-items"
          }
          ref={productItemsRef}
        >
          {filteredProducts.map((product) => (
            <div
              className={
                viewInLine ? "page-product-item-inline" : "page-product-item"
              }
              key={product.id}
            >
              <ProductItem productObject={product} />
            </div>
          ))}
        </div>
      </div>
      <div className="navbar-footer">
        <Navbar MenuClick={() => setMenuOpen(true)} />
        <OverlayComponent
          isOpen={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        />
      </div>
    </div>
  ) : (
    <div className="page-products-container-desktop">
      <div className="page-products-navbar">
        {" "}
        <NavbarDesktop></NavbarDesktop>
      </div>

      <div className="page-products-footer-content-desktop">
        <div className="page-products-content-desktop">
          <div className="container-desktop-page-products">
            <div className="top-info-desktop">
              <div className="filters-and-group-by-desktop">
                <div className="filters-desktop" onClick={handleClickFilters}>
                  Filters
                  <img
                    src={showFilters ? FiltersClose : Filters}
                    alt="Filter Icon"
                  />
                </div>
              </div>
              {showFilters && (
                <FiltersComponent
                  brands={brands}
                  descriptions={descriptions}
                  onFiltersChange={handleGetFilters}
                  onApplyFilters={handleCloseMenuApplyFilters}
                  initialSelectedTypes={selectedTypes}
                  initialScoreRange={ScoreRange}
                  initialSelectedBrands={selectedBrands}
                  onClickOutside={handleClickOutside}
                />
              )}
            </div>

            <div className="page-product-items-desktop" ref={productItemsRef}>
              {filteredProducts.map((product) => (
                <div
                  className={
                    viewInLine
                      ? "page-product-item-inline"
                      : "page-product-item"
                  }
                  key={product.id}
                >
                  <ProductItem productObject={product} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="navbar-footer-products">
          <FooterDesktop />
        </div>
      </div>
    </div>
  );
};

export default Products;
