// FloatingMenu.js
import React, { useState, useEffect, useRef } from "react";
import "./FloatingMenu.css";
import LanguageSelectionMenu from "../../desktop/LanguageSelection_menu";
//imagens
import dpp_overlay_logo from "../../../img/logo-dpp-overlay.png";
import dpp_supporters from "../../../img/supporters-dpp.png";

const FloatingMenu = ({ isOpen, onClose, touchAreaRef }) => {
  const [activeLanguage, setActiveLanguage] = useState("EN");
  const menuRef = useRef(null);
  const languages = ["EN", "PT", "DE", "ES", "FR"];


  const handleLanguageClick = (language) => {
    setActiveLanguage(language);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="container-floating" ref={touchAreaRef}>
      <div
        className={`overlay-menu ${isOpen ? "visible" : ""}`}
        onClick={onClose}
      ></div>
      <div className={`floating-menu ${isOpen ? "open" : ""}`} ref={menuRef}>
        <div className="floating-menu-content">
          <div className="floating-menu-container">
            <div className="menu-header">
            <LanguageSelectionMenu isHomepage={true} languages={languages} />
            </div>
            <a className="close-button" onClick={onClose}>
              <img
                src="./images/close-icon.svg"
                className="button-close-img"
                alt="Close"
              />
            </a>
          </div>
          <ul className="menu-items">
            <li>ABOUT</li>
            <li>FAQS</li>
            <li>HELP</li>
            <li>CONTACTS</li>
          </ul>
          <div className="menu-footer">
            {/* Adicionar logos */}
            <img
              src={dpp_supporters}
              alt="Citeve Partnerships"
              className="supporters-image"
            />
            <img
              src={dpp_overlay_logo}
              alt="Digital Product Passport"
              className="dpp-overlay-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingMenu;
