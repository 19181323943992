import React from "react";
import "./ButtonRedirect.css";
import { useNavigate } from "react-router-dom";

const ButtonRedirect = () => {
  const navigate = useNavigate();

  const handleNavClick = () => {
    navigate("/view");
  };

  return (
    <div onClick={handleNavClick} className="container-redirect-component">
      <div className="redirect-v2-input-component">
        <div className="background-layer">
          <div className="redirect-button-v2">
            <span className="text-redirect-button">Explore our DPPs</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonRedirect;
