import React, { useState, useRef, useImperativeHandle, useEffect } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import "./Aditionalinfo.css";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Fab } from "@mui/material";

function Carousel({ data, setCurrentVisibleSlide, refNextBack }) {
  const carouselRef = useRef();
  const ref = React.useRef();
  const [activeIndex,setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(() => {
    // Inicializa o estado com base na verificação de mídia
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  const handleSlideChange = (dataIndex) => {
    setCurrentVisibleSlide(dataIndex);
    setIndex(dataIndex);
    setActiveIndex(dataIndex);
  };

  const [index, setIndex] = useState();
  const currentItem = data.find(item => item.id === activeIndex);

  useImperativeHandle(
    refNextBack,
    () => ({
      goToNextSlide() {
        carouselRef.current?.goNext();
      },
      goToPrevSlide() {
        carouselRef.current?.goBack();
      },
    }),
    [carouselRef]
  );

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simule um tempo de carregamento
    setTimeout(() => {
      setLoaded(true);
    }, 1000); // Tempo de espera arbitrário para simular o carregamento
  }, []);

  return (
    <>
      <div
        className={`carousel-container-aditional ${
          loaded ? "" : "carousel-loading"
        }`}
      >
    {isMobile &&    <Fab
          className="material-button-left"
          size="small"
          style={{ backgroundColor: "transparent", boxShadow: "none" }}
          onClick={() => carouselRef.current?.goBack()}
        >
          <KeyboardArrowLeft style={{ fontSize: 20 }} />
        </Fab>
        } 
        <ResponsiveContainer
          carouselRef={carouselRef}
          render={(parentWidth, carouselRef) => {
            let currentVisibleSlide = 3;

            return (
              <>
                <StackedCarousel
                  ref={carouselRef}
                  data={data}
                  carouselWidth={parentWidth}
                  slideWidth={60}
                  slideComponent={Slide}
                  maxVisibleSlide={3}
                  currentVisibleSlide={currentVisibleSlide}
                  useGrabCursor={true}
                  onActiveSlideChange={(newCenterDataIndex) =>
                    handleSlideChange(newCenterDataIndex)
                  }
                  transitionTime={100}
                  swipeSpeed={0.3}
                />
                <div className="slideproducts-carousel-texts">
                  <div className="slideproduct-carousel-name">
                    {currentItem.text}
                  </div>
                </div>
              </>
            );
          }}
        />
      {isMobile &&  <Fab
          className="material-button-right"
          size="small"
          style={{ backgroundColor: "transparent", boxShadow: "none" }}
          onClick={() => carouselRef.current?.goNext()}
        >
          <KeyboardArrowRight style={{ fontSize: 20 }} />
        </Fab>
}
      </div>
    </>
  );
}

export default Carousel;

const Slide = React.memo(function (props) {
  const { data, dataIndex, isCenterSlide } = props;
  const { image, text } = data[dataIndex];

  return (
    <div className="slideproducts-carousel-maindiv">
      <div className="slideproduct-carousel-image">
        {isCenterSlide ? (
          <div className="slideproperty-image">
            <img alt="nome" draggable={false} src={image} />
          </div>
        ) : (
          <div className="slideproperty-image-opacity">
            <img
              className="slide-image"
              alt="nome"
              draggable={false}
              src={image}
            />
          </div>
        )}
      </div>
 {/*      <div className="slideproduct-carousel-texts">
        {isCenterSlide ? (
          <div className="slideproduct-carousel-name">{text}</div>
        ) : null}
      </div> */}
    </div>
  );
});