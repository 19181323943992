import React from 'react'
import "./FooterDesktop.css";

const FooterDesktop = () => {

        const openLink = (url) => {
          window.open(url, '_blank');
        };

  return (
    <div className="footer-container">
      <div className="footer-row1">
        <div className="footer-row1-left">
          <div className="footer-row1-left-cols">
            <div className="footer-titles">Contact Us</div>
            <div className="footer-texts-container">
              <div className="footer-texts">Phone Number</div>
              <div className="footer-texts">Email</div>
              <div className="footer-texts">Address</div>
            </div>
          </div>
          <div className="footer-row1-left-cols">
            <div className="footer-titles">Help</div>
            <div className="footer-texts-container">
              <div className="footer-texts click">Help Center</div>
              <div className="footer-texts click">Reviews</div>
            </div>
          </div>
          <div className="footer-row1-left-cols faqs">FAQs</div>
        </div>
        <div className="footer-row1-right">
          <div className="footer-socials">
            <div className="footer-titles">Social Network</div>
            <div className="icon-socials-container">
              <img
                src="./images/icon_instagram.svg"
                alt="icon-socials"
                className="icon-socials"
                onClick={() =>
                  openLink("https://www.instagram.com/beat_textiles")
                }
              />
              <img
                src="./images/icon_linkedin.svg"
                alt="icon-socials"
                className="icon-socials"
                onClick={() =>
                  openLink("https://www.linkedin.com/showcase/bioeconomyattextiles/about")
                }
              />
            </div>
          </div>
          <div className="footer-sponsors-container">
            <img
              src="./images/icon_sponsors_v2.svg"
              alt="icon-sponsors-v2"
              className="icon-sponsors-v2"
            />
          </div>
        </div>
      </div>
      <div className="footer-row2">
        <div className="footer-row2-texts">
          @Copyright 2024. All Rights Reserved.
        </div>
        <div className="terms-privacy-container">
          <div className="footer-row2-texts click">Terms of Use</div>
          <div className="footer-row2-texts click">Privacy Policy</div>
        </div>
      </div>
    </div>
  );
}

export default FooterDesktop